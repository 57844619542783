(function ($) {
  Drupal.behaviors.productInfoArea = {
    attach: function (context) {
      var $productFullInfoArea = $('.js-product-full-info-area', context);
      var $productInfoTriggers = $('.js-product-info-trigger', context);
      var $productInfoBlocks = $('.js-product-info', context);
      var $productInfoMobileBlocks = $('.js-product-info-area--wrapper', context);
      var $productInfoDefaultExpanded = $('.js-product-info-trigger.active', context);
      var updateActiveStatus = function () {
        var $triggerClicked = $(this);
        var $productInfoSection = $('.js-product-info[data-info-section="' + $triggerClicked.data('info-section') + '"]');

        if ($productInfoSection.hasClass('hidden')) {
          // Trigger changes
          $productInfoTriggers.removeClass('active');
          $triggerClicked.addClass('active');

          // Info block changes
          $productInfoBlocks.addClass('hidden');
          $productInfoSection.removeClass('hidden');
        } else if ($productFullInfoArea.length) {
          $triggerClicked.removeClass('active');
          $productInfoSection.addClass('hidden');
        }
      };

      // Collapse product full info area by default
      $productFullInfoArea.find('.js-product-info-trigger').removeClass('active');
      $productFullInfoArea.find('.js-product-info').addClass('hidden');

      // Position Mobile Blocks correctly when using the SPP - Product Layout - v1 with slots
      if ($productInfoMobileBlocks.closest('.product-info-area').length > 0) {
        $productInfoMobileBlocks.prependTo($productFullInfoArea);
      }

      $productInfoDefaultExpanded.each(updateActiveStatus);
      $productInfoTriggers.once().on('click', updateActiveStatus);
    }
  };
})(jQuery);
